import "./App.css";
import React, {useState, useEffect} from "react";
import axios from "axios";


function App() {
    //creating IP state
    const [ip, setIP] = useState("");
    const [state, setState] = useState("");
    const [serverIPresult, setByIP] = useState("");
    const [serverIP, setByIP2] = useState("");

    // Updated Code
    const queryParameters = new URLSearchParams(window.location.search)
    const host = queryParameters.get("host")
    const query = new URL(host);

    console.log(query.host)
    const qhost = query.host
    const getServerIP = async () => {
        return await axios.get("https://dns.google/resolve?name=" + qhost)
            .then(function (response) {
                return response.data.Answer[0].data.toString()
            })
    }


    const getClientIP = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };


    const checkConnectToHost = async () => {

        const res = await axios.get(host + "/api/ping")
            .then(function (response) {
                return response
            })
            .catch(function (error) {

                let req = {
                    "data": {
                        "state": error.stack,
                    }
                }
                return req;
            });
        setState(res.data.state);
    };

    const connectToHostByIP = async () => {
        const IP = await getServerIP()
        console.log("http://" + IP + "/check")

        const resByIP = axios.get("http://" + IP + "/check")
            .then(function (responseByIP) {
                console.log(responseByIP.data.state)
                const serverIPresult = {
                    "serverIPresult": responseByIP.data.state,
                }
                return serverIPresult
            }).catch(function (error) {
                console.log(error)
                const serverIPresult = {
                    "serverIPresult": error.stack,
                }
                return serverIPresult
            })
        setByIP((await resByIP).serverIPresult)
        const IPADD = {serverIP: IP}
        setByIP2(IPADD.serverIP)

    }

    useEffect(() => {
        //passing getData method to the lifecycle method

        getClientIP();
        checkConnectToHost();
        connectToHostByIP();
        getServerIP();


    }, []);


    if (state === "OK") {
        return (
            <div className="App">
                <h2>Your IP Address is</h2>
                <h4>{ip}</h4>
                <br/>
                <h2>Connect from client to {host}</h2>
                <h4>{state}</h4>
                <h2>Connect from client to {serverIP}</h2>
                <h4>{serverIPresult}</h4>
            </div>
        );
    } else {
        return (

            <div className="App">
                <h2>Your IP Address is</h2>
                <h4>{ip}</h4>
                <br/>
                <h2>Cannot connect to {host}</h2>
                <h4>{state}</h4>
                <h2>Connect from client to {serverIP} </h2>
                <h4>{serverIPresult}</h4>
            </div>
        );
    }
}

export default App;
